import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
    <Layout is404>
        <section id="banner">
            <div className="inner">
                <h2>TOO DEEP!</h2>
                <p>404! THE PAGE YOU ARE LOOKING FOR PROBABLY CANNOT SWIM?</p>
            </div>
        </section>
    </Layout>
);

export default IndexPage;